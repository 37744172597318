
import TasqJob from '@/interfaces/tasqs/TasqJob';
import gptModule from '@/store/modules/gptModule';
import { getFormattedDate } from '@/utils/helpers';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GptMixin from '../gpt/GptMixin';

@Component
export default class TasqWellStatusBanner extends mixins(GptMixin) {
  @Prop({ required: true }) tasq!: TasqJob;

  get wellWaitingOn() {
    let padWaitingOn = null;
    const wellWaitingOn = gptModule.waitingOnStatusByWellName(this.tasq.wellName);
    if (this.tasq.padName) {
       padWaitingOn = gptModule.waitingOnStatusByWellName(this.tasq.padName);
    }

    return this.getRcentWaitingOnStatus({ wellWaitingOn, padWaitingOn });
  }

  get waitingOnReason() {
    return this.wellWaitingOn ? this.wellWaitingOn.body : '';
  }

  get downtimeCode() {
    const downtimeCodes = gptModule.wellDowntimeCodes(this.tasq.wellName);
    return downtimeCodes && downtimeCodes.localVal !== 'Available' ? downtimeCodes : null;
  }

  get waitingOnComment() {
    if (this.wellWaitingOn) {
      return (this.wellWaitingOn.summary
    || this.wellWaitingOn.summary !== this.wellWaitingOn.body
      ? this.wellWaitingOn.summary
      : 'N/A') || 'N/A';
    }
      return 'N/A';
  }

  get waitingOnStartDate() {
    return this.wellWaitingOn && this.wellWaitingOn.start_date
      ? getFormattedDate(new Date(this.wellWaitingOn.start_date))
      : 'N/A';
  }

  get waitingOnEndDate() {
    return this.wellWaitingOn && this.wellWaitingOn.end_date
      ? getFormattedDate(new Date(this.wellWaitingOn.end_date))
      : 'N/A';
  }

  get downtimeCodeDate() {
    return this.downtimeCode && this.downtimeCode.point_time
      ? getFormattedDate(new Date(this.downtimeCode.point_time))
      : 'N/A';
  }
}
